body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  display: flex;
  overflow: auto;
  overflow-x: hidden;
  background: grey;
}

small {
  font-size: xx-small;
}

a {
  text-decoration: none;
}

a:link {
  color: black;
}

a:hover {
  color: coral;
}

#root {
  width: 100%;
}
